import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { OutlineChevronDownIcon } from "@/components/Icons/components/HeroIcons/OutlineChevronDownIcon.component";
import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useUser } from "@/contexts/user/user.context";
export function AccountButton() {
  const {
    urls
  } = useUser();
  return <Menu data-sentry-element="Menu" data-sentry-component="AccountButton" data-sentry-source-file="AccountButton.component.tsx">
      {({
      open
    }: {
      readonly open: boolean;
    }) => <>
          <Menu.Button className={clsx("inline-flex min-h-6 select-none items-center justify-center gap-1 rounded-sm bg-primary px-3 py-1 text-sm font-theme-bold uppercase text-white transition duration-200 hover:bg-primary-hover active:enabled:scale-99 disabled:border-whisper disabled:text-smoke motion-reduce:transition-none lg:min-h-5")}>
            <Translation da="Min Konto" de="Mein Konto" en="My account" no="Min konto" sv="Mitt konto" />
            <OutlineChevronDownIcon className={clsx("h-3 w-3 transform-gpu ease-out", open && "rotate-180")} />
          </Menu.Button>
          <Transition as={Fragment} enter="transition motion-reduce:transition-none ease-out origin-top-left duration-200" enterFrom="opacity-0 transform-gpu scale-90" enterTo="opacity-100 transform-gpu scale-100" leave="transition motion-reduce:transition-none origin-top-left ease-in duration-100" leaveFrom="opacity-100 transform-gpu scale-100" leaveTo="opacity-0 transform-gpu scale-90">
            <Menu.Items as="ul" className="absolute right-0 top-full min-w-xs border border-silver bg-white text-black">
              <li className="border-b border-silver last:border-b-0">
                <Menu.Item>
                  <Link className={clsx("flex h-6 w-full items-center justify-between px-2 py-1 text-sm font-theme-bold uppercase transition-colors hover:text-primary")} href={urls.manageAccount} openInNewTab="determineFromSource">
                    <Translation da="Administrér konto" de="Konto verwalten" en="Manage account" no="Oversikt" sv="Hantera konto" />
                  </Link>
                </Menu.Item>
              </li>
              <li className="border-b border-silver last:border-b-0">
                <Menu.Item>
                  <a className={clsx("flex h-6 w-full items-center justify-between px-2 py-1 text-sm font-theme-bold uppercase transition-colors hover:text-primary")} href={urls.logoutAndReturnUrl}>
                    <Translation da="Log ud" de="Abmelden" en="Log out" no="Logg ut" sv="Logga ut" />
                  </a>
                </Menu.Item>
              </li>
            </Menu.Items>
          </Transition>
        </>}
    </Menu>;
}