import { clsx } from "clsx";
import type { MouseEventHandler, ReactNode } from "react";
type RegularButtonProps = {
  readonly className?: string;
  readonly disabled?: boolean; // eslint-disable-line react/boolean-prop-naming -- The name should match the official one in HTML.
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly children: ReactNode;
  readonly variant?: "primary" | "accent";
};
export function RegularButton({
  className,
  disabled,
  onClick,
  children,
  variant = "primary"
}: RegularButtonProps) {
  return <button className={clsx(className, "base-button base-hover-button text-white", variant === "primary" && "bg-primary hover:bg-primary-hover", variant === "accent" && "bg-accent hover:bg-accent-hover")} disabled={disabled} type="button" onClick={onClick} data-sentry-component="RegularButton" data-sentry-source-file="RegularButton.component.tsx">
      {children}
    </button>;
}