"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import type { LinkProps } from "../Link/Link.component";
import { Link } from "../Link/Link.component";
type FrontPageLinkProps = {
  readonly children: ReactNode;
};
export function FrontPageLink({
  children,
  ...linkProps
}: Omit<LinkProps, "href"> & FrontPageLinkProps) {
  const pathName = usePathname();
  const {
    position,
    testId,
    className,
    ...rest
  } = linkProps;
  return pathName === "/" ? <a href="/"
  // eslint-disable-next-line react/jsx-props-no-spreading -- We want to spread the props to the a tag
  {...rest} className={clsx("hyphens-manual break-words", className)} data-k5a-pos={position} data-testid={testId}>
      {children}
    </a> :
  // eslint-disable-next-line react/jsx-props-no-spreading -- We want to spread the props to the Link component
  <Link href="/" {...linkProps}>
      {children}
    </Link>;
}