import clsx from "clsx";
import type { ReactNode } from "react";
type ToolbarMenuItemProps = {
  readonly children: ReactNode;
  readonly className?: string;
};
export function ToolbarMenuItem({
  children,
  className
}: ToolbarMenuItemProps) {
  return <li className={clsx("relative flex h-full items-center justify-center before:relative before:right-1.25 before:block before:h-2 before:w-px before:bg-silver first:before:w-0 first:before:bg-transparent", className)} data-sentry-component="ToolbarMenuItem" data-sentry-source-file="ToolbarMenuItem.component.tsx">
      {children}
    </li>;
}