import clsx from "clsx";
import { Logo } from "@/components/Logo/Logo.component";
import { ToolbarMenu } from "../ToolbarMenu/ToolbarMenu.component";
type AppBarProps = {
  readonly className?: string;
  readonly theme?: "primary" | "white";
};
export function AppBar({
  className,
  theme = "white"
}: AppBarProps) {
  return <div className={clsx("flex h-9 w-full items-center justify-center", theme === "white" && "border-b border-b-silver bg-white", theme === "primary" && "bg-primary text-white", className)} data-sentry-component="AppBar" data-sentry-source-file="AppBar.component.tsx">
      <div className="container flex h-full w-full items-center justify-between gap-2 lg:flex-col lg:gap-0">
        <div className="relative flex w-full grow items-center justify-center">
          <div className="flex w-full items-center justify-between gap-x-2">
            <div className="flex items-end gap-1">
              <Logo className="h-4" primaryClassName={theme === "white" ? "fill-site-logo-primary" : "fill-white"} size="sm" data-sentry-element="Logo" data-sentry-source-file="AppBar.component.tsx" />
            </div>
            <ToolbarMenu theme={theme === "primary" ? "white" : "primary"} data-sentry-element="ToolbarMenu" data-sentry-source-file="AppBar.component.tsx" />
          </div>
        </div>
      </div>
    </div>;
}