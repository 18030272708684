import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
type MenuItemProps = {
  readonly targetUrl: string;
  readonly className?: string;
  readonly children: ReactNode;
  readonly position?: string;
};
export function MenuItem({
  targetUrl,
  children,
  className,
  position
}: MenuItemProps) {
  return <Link className={clsx(className, "flex w-full items-center text-center text-sm uppercase")} href={targetUrl} openInNewTab="determineFromSource" position={position} data-sentry-element="Link" data-sentry-component="MenuItem" data-sentry-source-file="MenuItem.component.tsx">
      {children}
    </Link>;
}