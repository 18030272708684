"use client";

import type { Dispatch, SetStateAction } from "react";
import { Hamburger } from "@/components/Icons/components/Hamburger/Hamburger.component";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
type ToolbarMenuButtonToggleMenuProps = {
  readonly isNavOpen: boolean;
  readonly onToggle: Dispatch<SetStateAction<boolean>>;
};
export function ToolbarMenuButtonToggleMenu({
  isNavOpen,
  onToggle
}: ToolbarMenuButtonToggleMenuProps) {
  return <button className="flex h-full w-full flex-col items-center justify-center" type="button" onKeyDown={event => {
    if (event.key === "Enter") {
      onToggle(previous => !previous);
    }
  }} onMouseDown={() => {
    onToggle(previous => !previous);
  }} data-sentry-component="ToolbarMenuButtonToggleMenu" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Menu",
      de: "Menu",
      en: "Menu",
      no: "Meny",
      sv: "Meny"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx">
        <Hamburger className="h-3 w-3" isNavOpen={isNavOpen} data-sentry-element="Hamburger" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx" />
      </ToolbarMenuDenseItem>
    </button>;
}