/**
 * A tagged template function that lets you interpolate JSX elements within a template string.
 *
 * @example
 * // Input:
 * inlineJsx`Click ${<a>here</a>} to win a ${<span class="font-bold">prize</span>}!`
 *
 * // Output:
 * <span>Click <a>here</a> to win a <span class="font-bold">prize</span>!</span>
 */
import { Fragment } from "react";
export function inlineJsx(stringFragments: TemplateStringsArray, ...elements: ReadonlyArray<JSX.Element | number | string>): JSX.Element {
  return <span data-sentry-component="inlineJsx" data-sentry-source-file="inline-jsx.tsx">
      {elements.map((element, index) =>
    // eslint-disable-next-line react/no-array-index-key -- Template string literals have a constant number of arguments; hence, the array index ought to be a stable key.
    <Fragment key={index}>
          {stringFragments[index]}
          {element}
        </Fragment>)}
      {/* The `stringFragments` array is guaranteed to have exactly one more item than `elements`. */}
      {stringFragments[stringFragments.length - 1]}
    </span>;
}