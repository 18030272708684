import type { HeaderModel } from "@/components/Header/models/header-model.types";
import { Link } from "@/components/Link/Link.component";
type DrawerListProps = {
  readonly items: ReadonlyArray<HeaderModel.MenuItem>;
};
export default function DrawerList({
  items
}: DrawerListProps) {
  return <ul data-sentry-component="DrawerList" data-sentry-source-file="DrawerList.component.tsx">
      {items.map(item => <li className="flex min-h-6 items-center border-b border-whisper transition-colors" key={item.key}>
          <div className="container">
            <Link className="flex h-full w-full items-center text-sm" href={item.url} openInNewTab="determineFromSource">
              <div className="font-theme-bold uppercase">{item.name}</div>
            </Link>
          </div>
        </li>)}
    </ul>;
}