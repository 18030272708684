"use client";

import { useEffect, useRef, useState } from "react";

type ScrollDirection = "down" | "idle" | "up";

type UseScrollDirection = {
  readonly isScrollingDown: boolean;
  readonly isScrollingUp: boolean;
};

type UseScrollDirectionProps = {
  readonly scrollYDeltaThreshold?: number;
};

const scrollYDeltaThresholdDefault = 16;

export function useScrollDirection({
  scrollYDeltaThreshold = scrollYDeltaThresholdDefault,
}: UseScrollDirectionProps = {}): UseScrollDirection {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection>("idle");
  const lastScrollY = useRef(0);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    function onScroll() {
      if (window.scrollY < 0) {
        return;
      }

      const scrollYDelta = window.scrollY - lastScrollY.current;
      lastScrollY.current = window.scrollY;

      if (Math.abs(scrollYDelta) >= scrollYDeltaThreshold) {
        setScrollDirection(scrollYDelta < 0 ? "up" : "down");
      }
    }

    return function cleanUp() {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return {
    isScrollingDown: scrollDirection === "down",
    isScrollingUp: scrollDirection === "up",
  };
}
